<template lang="pug">
    .select-the-article.mb-3(v-if="diff").pb-12
        //- debug answers = {{answers}}
        .elements(ref="elements")
            v-row(align="start" justify="space-between" :class="{'flex-column-reverse': questionNum}").flex-md-row
                v-col(cols="12", md="9")
                    .element.d-inline(:ref="'element-' + itmIdx", v-for="itm, itmIdx in diff", :key="itmIdx", :data-added="itm.added", :data-removed="itm.removed", :class="calcClass(itm, itmIdx)" @click="")
                        .words.d-inline
                            .word.d-inline-block.mr-1(v-for="word, wordIdx in itm.value.trim().split(' ')")
                                template(v-if="( word.startsWith('[') && word.endsWith(']') ) || itm.added")
                                    .selector.d-inline-block
                                        v-select.mr-2.ml-md-2(:items="articleOptions" v-model="answers[itmIdx]" :data-content="answers[itmIdx] || ''" outlined flat dense hide-details :background-color="calcSelectBackgroundColor(answers[itmIdx], word)" :dark="bgColor !== 'white'" :style="{width: '100px'}" :readonly="answerWasCheckedAndIsCorrect || showAnswer")

                                span.text.mr-2(:data-content="word || ''", v-if="!itm.added") {{word | cleanNoun}}

                    v-expand-transition
                      .explanation(v-if="showExplanation", :style="{color: bgColor === 'white' ? null : $vuetify.theme.themes.light[bgColor]}")
                        v-divider.mt-3.mb-3
                        span.correctOrIncorrect(v-if="answerWasChecked") {{answerIsCorrect ? 'CORRECT! ' : 'Sorry! '}}
                        | {{explanation}}

                v-col(cols="12", md="3" v-if="!answerWasCheckedAndIsCorrect && !showAnswer && !questionNum")
                    v-btn(outlined @click="handleCheckAnswer" block) Check answer

                v-col(cols="12", md="3", v-else-if="questionNum").text-right
                  strong Question {{questionNum}}

</template>

<script>
// import { nanoid } from "nanoid";
import { diffWords } from "diff";
export default {
  name: "SelectTheArticle",
  props: {
    incorrect: { type: String, required: true },
    correct: { type: String, required: true },
    explanation: { type: String, required: true },
    questionNum: { type: Number, default: null },
    showAnswer: { type: Boolean, default: false },
  },
  filters: {
    cleanNoun(str) {
      return str.split("[").join("").split("]").join("");
    },
  },
  watch: {
    answers: {
      handler() {
        this.bgColor = "white";
        this.answerWasChecked = false;
        this.$nextTick(() => {
          if (document.querySelector(".select-the-article")) {
            const contentElements = [
              ...this.$refs.elements.querySelectorAll("[data-content]"),
            ];
            const content = contentElements
              .map((elem) => elem.dataset.content)
              .join(" ");

            const strCorrect = this.correct.toLowerCase();
            const strContent = content
              .split("[")
              .join("")
              .trim()
              .split("]")
              .join("")
              .trim()
              .split("  ")
              .join("")
              .toLowerCase();

            // window.debug('Comparing strCorrect', strCorrect, 'to strContent', strContent)

            this.answerIsCorrect = strCorrect === strContent;
            this.$emit("answered", {
              questionNum: this.questionNum,
              correct: this.answerIsCorrect,
            });
          } else {
            return false;
          }
        });
      },
      deep: true,
    },
    showAnswer(newVal) {
      if (newVal) this.handleCheckAnswer();
    },
  },
  data() {
    return {
      diff: null,
      numFound: 0,
      id: null,
      bgColor: "white",
      answers: {},
      answerIsCorrect: false,
      answerWasChecked: false,
      showExplanation: false,
      articleOptions: [
        { text: "-", value: "" },
        { text: "an", value: "an" },
        { text: "a", value: "a" },
        { text: "the", value: "the" },
      ],
    };
  },
  created() {
    this.init();
    this.$emit("answered", {
      questionNum: this.questionNum,
      correct: this.answerIsCorrect,
    });
  },
  computed: {
    answerWasCheckedAndIsCorrect() {
      return this.answerIsCorrect && this.answerWasChecked;
    },
  },
  methods: {
    init() {
      this.diff = diffWords(this.incorrect, this.correct, { ignoreCase: true });

      this.diff.forEach((itm, idx) => {
        if (itm.added || itm.value.startsWith("["))
          this.$set(this.answers, idx, "");
      });
    },
    calcClass(itm) {
      if (itm.removed) return "waiting-to-remove";
      else if (itm.added) return "waiting-to-add";
      else return null;
    },
    calcSelectBackgroundColor(givenAnswer, correctAnswer) {
      if (this.showAnswer || this.answerWasChecked) {
        return givenAnswer.toLowerCase() ===
          correctAnswer.toLowerCase().split("[]").join("")
          ? "success"
          : "error";
      } else {
        return "white";
      }
    },
    handleCheckAnswer() {
      this.bgColor = this.answerIsCorrect ? "success" : "error";
      this.showExplanation = true;
      this.answerWasChecked = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/colors.scss";

.select-the-article {
  background: $secondary;
  padding: 2rem;
  padding-right: 2rem;
  font-size: 1.75rem;
  line-height: 2;
  cursor: default;
}

.word {
  font-size: 1.5rem;
}

.explanation {
  font-size: 1rem;
}

.ghosted {
  opacity: 0;
}
</style>
