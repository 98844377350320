<template lang="pug">
  v-card.test-results.pa-12.text-center(:style="{border: '2px solid ' + resultColor, color: resultColor}" flat shaped)
   v-icon(size="4rem" :color="resultColor") mdi-medal
   h1.mt-6.mb-3(v-if="percentCorrect >= 90") You're amazing!
   h1.mt-6.mb-3(v-else-if="percentCorrect >= 80") Nice job!
   h1.mt-6.mb-3(v-else-if="percentCorrect >= 70") Not bad!
   h1.mt-6.mb-3(v-else) How about some more practice?

   h2 You got {{numCorrect}} questions correct out of {{totalQuestions}} total questions

   p.mt-6(v-if="percentCorrect <= 70") You can try this test again, after getting some
    a.font-weight-bold.mx-2(@click.prevent="grammarSectionNav({lesson:'articles', section: 'practice'})") more practice.
   v-btn.mt-6(outlined :block="$vuetify.breakpoint.smAndDown" :color="resultColor", @click="$emit('checkAnswers')")
       v-icon.mr-1 mdi-chevron-up
       | Check answers
       v-icon.ml-1 mdi-chevron-up
</template>

<script>
import { call } from "vuex-pathify";
export default {
  name: "TestResults",
  props: {
    numCorrect: { type: Number, required: true },
    totalQuestions: { type: Number, required: true },
  },
  computed: {
    percentCorrect() {
      return Math.floor((this.numCorrect / this.totalQuestions) * 100);
    },
    resultColor() {
      if (this.percentCorrect > 90)
        return this.$vuetify.theme.themes.light.success;
      else if (this.percentCorrect > 80) return "darkgreen";
      else if (this.percentCorrectcent > 60)
        return this.$vuetify.theme.themes.light.warning;
      else return this.$vuetify.theme.themes.light.error;
    },
  },
  methods: {
    ...call({ grammarSectionNav: "Nav/grammarSectionNav" }),
  },
};
</script>
