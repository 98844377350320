<template lang="pug">
    .section-navigation
        v-container(fluid dark).primary.my-n1
          v-breadcrumbs(:items="items" large dark).justify-center
              template(v-slot:divider)
                  v-icon mdi-chevron-right
              template(v-slot:item="{ item }")
                  v-breadcrumbs-item(href="#" @click.prevent="grammarSectionNav({lesson: $route.params.lesson, section: item.section})" :disabled="$route.params.section.toLowerCase() === item.section.toLowerCase()")
                    span(:class="{'accent--text': $route.params.section.toLowerCase() !== item.section.toLowerCase()}") {{item.text}}

</template>

<script>
import { call } from "vuex-pathify";
export default {
  name: "GrammarSectionNavigation",
  data() {
    return {
      items: [
        {
          text: "Cheatsheet",
          disabled: false,
          section: "cheatsheet",
        },
        {
          text: "WarmUp",
          disabled: false,
          section: "warmup",
        },
        {
          text: "Practice",
          disabled: false,
          section: "practice",
        },
        {
          text: "Test Yourself",
          disabled: false,
          section: "test",
        },
        {
          text: "Learning Links",
          disabled: false,
          section: "resources",
        },
      ],
    };
  },
  methods: {
    ...call({ grammarSectionNav: "Nav/grammarSectionNav" }),
  },
};
</script>

<style lang="scss" scoped></style>
