<template lang="pug">
    .warmup
      GrammarSectionNavigation
      GrammarIntro
        template(v-slot:title)
          h1 Warm Up :: articles (a/an/the)
        template(v-slot:content)
          p.large The sentences below have some errors.
          ol.large
              li.mb-3 Click on any words that need to be removed
              li Click in the space before a word if you think something is missing

          v-expand-transition
            #exercise.exercise(v-if="showQuestion >= 0").mt-6
              keep-alive
                ClickTheDiff.mb-1(v-if="showQuestion <= questions.length - 1", :incorrect="questions[showQuestion].incorrect", :correct="questions[showQuestion].correct", :key="currentTry + '-' + questions[showQuestion].key", offerTryMore, :offerGoBack="showQuestion > 0", :offerTryAnother="showQuestion < questions.length - 1" @loadMore="handleLoadMore" @restart="handleRestart" @doGoBack="handleGoBack")

              v-expand-transition
                v-card.congrats.pa-5(outlined v-if="showQuestion >= questions.length")
                  .text-center
                    v-icon(size="4rem" color="success") mdi-check-circle
                  h2.mt-6 Terrific!
                  p Now that you have finished the Warm Up, let's move on to some practice exercises
                  v-btn.font-weight-bold(large block depressed color="primary" @click="grammarSectionNav({lesson: 'articles', section: 'practice'})") Try Practice

</template>

<script>
import { call } from "vuex-pathify";
import { waitForElemToExist, scrollToTarget } from "@/helpers";

import GrammarIntro from "@/components/Grammar/Intro.vue";
import GrammarSectionNavigation from "@/components/Nav/GrammarSectionNavigation.vue";

import ClickTheDiff from "@/components/Tools/ClickTheDiff.vue";
export default {
  name: "IdentifyArticles",
  components: { GrammarSectionNavigation, GrammarIntro, ClickTheDiff },
  data() {
    return {
      showQuestion: 0,
      currentTry: 0,
      questions: [
        {
          key: "rome-0",
          correct:
            "A few years ago, I took a trip to Rome that I will never forget. It was just amazing! I went there with a good friend. We decided to go by car. I try to avoid airplanes if I can. They don't look safe to me.",
          incorrect:
            "A few years ago, I took trip to the Rome that I will never forget. It was just the amazing! I went there with good friend. We decided to go by a car. I try to avoid the airplanes if I can. They don't look safe to me.",
        },
        {
          key: "rome-1",
          correct:
            "Well, we left early in the morning on a rainy Friday and arrived in the evening. We stopped a few times to go to the bathroom, to get gas, and to buy a snack.",
          incorrect:
            "Well, we left early in morning on rainy Friday and arrived in evening. We stopped few times to go to bathroom, to get gas, and to buy snack.",
        },
        {
          key: "rome-2",
          correct:
            "We slept well and the next morning we visited a lot of interesting places, but the Coliseum was the most outstanding. On Saturday night, my cousin and her husband (who live there) gave me a call and invited us to a restaurant near the hotel where we were staying.",
          incorrect:
            "We slept well and next morning we visited lot of interesting places, but Coliseum was most outstanding. On Saturday night, my cousin and her husband (who live there) gave me call and invited us to restaurant near hotel where we were staying.",
        },
        {
          key: "rome-3",
          correct:
            "They had not seen me in over a year, and I had lost a lot of weight since the last time we had met. So, before our dinner, I went shopping and bought a pretty dress. The dress fit me perfectly. I also bought a nice pair of shoes to go with the dress. The shoes were quite expensive, but I wanted to make a good impression.",
          incorrect:
            "They had not seen me in over year, and I had lost lot of weight since last time we had met. So, before our dinner, I went shopping and bought pretty dress. Dress fit me perfectly. I also bought nice pair of shoes to go with dress. Shoes were quite expensive, but I wanted to make good impression.",
        },
        {
          key: "rome-4",
          correct:
            "At the same shop, I saw a nice purse, but I didn't buy it. I just took the red purse that my mom had given me the previous summer as a birthday present.",
          incorrect:
            "At same shop, I saw nice purse, but I didn't buy it. I just took red purse that my mom had given me previous summer as birthday present.",
        },
        {
          key: "rome-5",
          correct:
            'Our whole evening was like a movie scene. My cousin was shocked when she saw me, and said, "you look like a model". We talked and laughed the whole time, and we each had a small italian pizza of our own, and a glass of red wine. It was a wonderful way to end the trip.',
          incorrect:
            'Our whole evening was like movie scene. My cousin was shocked when she saw me, and said, "you look like model". We talked and laughed whole time, and we each had small italian pizza of our own, and glass of red wine. It was wonderful way to end trip.',
        },
      ],
    };
  },
  methods: {
    ...call({ grammarSectionNav: "Nav/grammarSectionNav" }),
    async handleLoadMore() {
      this.showQuestion++;

      await waitForElemToExist("#exercise");
      const target = document.getElementById("exercise");
      scrollToTarget(target);
    },
    handleGoBack() {
      if (this.showQuestion > 0) {
        // this.currentTry--
        this.showQuestion--;
        scrollToTarget(document.getElementById("exercise"));
      }
    },
    handleRestart() {
      this.currentTry++;
      this.showQuestion = 0;
      scrollToTarget(document.getElementById("exercise"));
    },
  },
};
</script>

<style lang="scss" scoped></style>
