<template lang="pug">
    .grammar
        component(v-if="component", :is="component")
        template(v-else)
            v-skeleton-loader(type="card")
            v-skeleton-loader(type="article")
            v-skeleton-loader(type="card")
</template>

<script>
import { sync } from "vuex-pathify";
// Articles
import ArticlesCheatsheet from "@/components/Grammar/Articles/Cheatsheet.vue";
import ArticlesWarmUp from "@/components/Grammar/Articles/WarmUp.vue";
import ArticlePractice from "@/components/Grammar/Articles/Practice.vue";
import ArticleTest from "@/components/Grammar/Articles/Test.vue";
import ArticleResources from "@/components/Grammar/Articles/Resources.vue";
export default {
  name: "Grammar",
  components: { ArticlesCheatsheet },
  watch: {
    $route() {
      this.init();
    },
    grammarActiveComponent(newVal) {
      window.debug("about to update component with val", newVal);
      const { lesson, section } = newVal;
      if (lesson === "articles") {
        if (section === "cheatsheet") this.component = ArticlesCheatsheet;
        else if (section === "warmup") this.component = ArticlesWarmUp;
        else if (section === "practice") this.component = ArticlePractice;
        else if (section === "test") this.component = ArticleTest;
        else if (section === "resources") this.component = ArticleResources;
      }
    },
  },
  data() {
    return { component: null };
  },
  created() {
    this.init();
  },
  computed: {
    ...sync({ grammarActiveComponent: "Nav/grammarActiveComponent" }),
  },
  methods: {
    init() {
      const { lesson, section } = this.$route.params;
      window.debug("\n\nlesson", lesson, "section", section);

      this.grammarActiveComponent = { lesson, section };
    },
  },
};
</script>
