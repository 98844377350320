<template lang="pug">
    .section-introduction.pa-5.white
        v-container
            .intro.mb-6.text-center.text-md-left
                slot(name="title")
            slot(name="content")
</template>

<script>
export default {
  name: "Intro",
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/colors.scss";

.section-introduction {
  background: $secondary;
}
</style>
