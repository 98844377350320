<template lang="pug">
    .practice
      GrammarSectionNavigation
      GrammarIntro
          template(v-slot:title)
            h1 Practice :: articles (a/an/the)
          template(v-slot:content)
            p.large.mb-6 The sentences in this section are missing something.
            ol.large
                li.mb-3 Some of the nouns need articles (i.e. a/an/the) and some don't.
                li.mb-3 Select the best article for each noun (or none)
                li We have hundreds of these sentences, so you can press the "Mix & Try Again" button to shuffle and get more sentences.

            template(v-if="!items || items.length < 1")
              .mt-6
                v-btn.mb-3.mb-md-0.font-weight-bold(large :block="$vuetify.breakpoint.smAndDown", outlined color="success" @click="doLoad('BEGINNER')") Try Beginner
                v-btn.mb-3.mb-md-0.ml-md-2(large :block="$vuetify.breakpoint.smAndDown", outlined color="warning" @click="doLoad('INTERMEDIATE')") Try Intermediate
                v-btn.mb-3.mb-md-0.ml-md-2(large :block="$vuetify.breakpoint.smAndDown", outlined color="primary" @click="doLoad('ADVANCED')") Try Advanced

      v-expand-transition(v-if="items && items.length > 0")
          .exercises.white.mt-12.pb-12
              .exercise(v-for="itm, itmIdx in items", :key="'select-the-article-' + itmIdx + '-try-num-' + tryNum")
                  SelectTheArticle(:correct="itm.question.correct", :incorrect="itm.question.incorrect", :explanation="itm.explanation" :key="'select-the-article-' + itmIdx + '-question'")

              template(v-if="lastLevel")
                .text-center
                  v-btn.mb-3.mb-md-0(depressed tile outlined :block="$vuetify.breakpoint.smAndDown", @click="doLoad(lastLevel)")
                    v-icon.mr-1 mdi-shuffle-variant
                    | Mix & Try Again
                  v-btn.mb-1.mb-md-0.ml-md-1(v-if="lastLevel !== 'BEGINNER'" outlined color="orange" tile :block="$vuetify.breakpoint.smAndDown", @click="doLoad('BEGINNER')")
                    v-icon.mr-1 mdi-numeric-1-circle-outline
                    | Switch to Beginner
                  v-btn.mb-1.mb-md-0.ml-md-1(v-if="lastLevel !== 'INTERMEDIATE'" depressed tile color="warning" :block="$vuetify.breakpoint.smAndDown", @click="doLoad('INTERMEDIATE')")
                    v-icon.mr-1 mdi-numeric-2-circle-outline
                    | Switch to Intermediate
                  v-btn.ml-md-1(v-if="lastLevel !== 'ADVANCED'" depressed tile color="primary" :block="$vuetify.breakpoint.smAndDown", @click="doLoad('ADVANCED')")
                    v-icon.mr-1 mdi-numeric-3-circle-outline
                    | Switch to Advanced

                .text-center.mt-12.mb-6
                  v-btn.mt-3(x-large outlined block tile color="success" @click="grammarSectionNav({lesson:'articles', section:'test'})")
                    | Ready to Test Yourself?
                    v-icon.ml-1 mdi-arrow-right

</template>

<script>
import { call } from "vuex-pathify";
import GrammarSectionNavigation from "@/components/Nav/GrammarSectionNavigation.vue";
import GrammarIntro from "@/components/Grammar/Intro.vue";
import SelectTheArticle from "@/components/Tools/Articles/SelectTheArticle.vue";
import { articlesList } from "@/lib/articlesList.js";
import {
  waitForElemToExist,
  randomFromArray,
  scrollToTarget,
} from "@/helpers.js";
export default {
  name: "Practice",
  components: { GrammarSectionNavigation, GrammarIntro, SelectTheArticle },
  data() {
    return {
      lastLevel: "BEGINNER",
      startedSections: [],
      items: [],
      tryNum: 0,
    };
  },
  created() {
    // this.init()
  },
  computed: {},
  methods: {
    ...call({ grammarSectionNav: "Nav/grammarSectionNav" }),

    async doLoad(level) {
      this.items = [];
      this.lastLevel = level;

      this.tryNum++;
      this.setList(level);

      await waitForElemToExist(".select-the-article");
      scrollToTarget(document.querySelector(".select-the-article"), 200);
    },
    setList(level = "BEGINNER") {
      // const randomArticles = []

      const allItems = [];

      articlesList.forEach((itm) => {
        itm.questions.forEach((question) => {
          allItems.push({
            explanation: itm.explanation,
            question,
            type: itm.type,
          });
        });
      });

      let subset = allItems;
      if (level === "BEGINNER") {
        const setOne = allItems.filter((itm) => itm.type < 5);
        const setTwo = allItems.filter((itm) => itm.type >= 5 && itm.type < 10);
        const setThree = allItems.filter(
          (itm) => itm.type > 10 && itm.type < 14
        );

        subset = [
          ...randomFromArray(setOne, 5),
          ...randomFromArray(setTwo, 4),
          ...randomFromArray(setThree, 1),
        ];
      } else if (level === "INTERMEDIATE") {
        const setOne = allItems.filter((itm) => itm.type < 5);
        const setTwo = allItems.filter((itm) => itm.type >= 5 && itm.type < 10);
        const setThree = allItems.filter(
          (itm) => itm.type > 10 && itm.type < 20
        );

        subset = [
          ...randomFromArray(setOne, 2),
          ...randomFromArray(setTwo, 3),
          ...randomFromArray(setThree, 5),
        ];
      } else if (level === "ADVANCED") {
        // const setOne = allItems.filter(itm => itm.type < 5)
        const setTwo = allItems.filter((itm) => itm.type >= 5 && itm.type < 10);
        const setThree = allItems.filter((itm) => itm.type > 10);

        subset = [
          ...randomFromArray(setTwo, 2),
          ...randomFromArray(setThree, 8),
        ];
      }

      this.items = subset;
    },
  },
};
</script>
