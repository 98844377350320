<template lang="pug">
    .resources
      GrammarSectionNavigation
      GrammarIntro
          template(v-slot:title)
            h1 Resources to help you learn Articles (a/an/the)
          template(v-slot:content)
            p.large We collected some useful resources from around the internet to help you learn & improve your usage of English articles.
            p.large
                strong We did not produce these videos and we do not own them. They are on Youtube from other creators.

      v-container.mt-6
        v-row(justify="center", align="stretch")
            v-col.grid-col(cols="12" md="6").text-center
              VideoResource(title="Articles - 'a', 'an' and 'the' | English Grammar", videoId="zmR_CYJrz8o", :display="display")
            v-col.grid-col(cols="12" md="6").text-center
              VideoResource(title="English Articles - 3 Simple Rules To Fix Common Grammar Mistakes & Errors", videoId="uU-RbEEolw0", :display="display")

            v-col.grid-col(cols="12" md="6").text-center
              VideoResource(title="Articles - 6 Minute Grammar (from BBC)", videoId="ryRu8zFCKZE", :display="display")

            v-col.grid-col(cols="12" md="6").text-center
              VideoResource(title="Grammar: Using THE with common and abstract nouns", videoId="McQnOYChcGs", :display="display")
</template>

<script>
import VideoResource from "@/components/Tools/VideoResource.vue";
import GrammarSectionNavigation from "@/components/Nav/GrammarSectionNavigation.vue";
import GrammarIntro from "@/components/Grammar/Intro.vue";
import { waitForElemToExist } from "@/helpers";

export default {
  name: "Resources",
  components: { GrammarSectionNavigation, GrammarIntro, VideoResource },
  data() {
    return {
      display: {
        containerWidth: null,
        colHeight: 400,
      },
    };
  },
  mounted() {
    this.adjustSizing();
  },
  methods: {
    async adjustSizing() {
      const sel = ".grid-col .v-card__text";
      await waitForElemToExist(sel);

      this.display.containerWidth = Math.floor(
        document.querySelector(sel).offsetWidth * 0.85
      );

      //   equalize column heights
      const allCols = document.querySelectorAll(".grid-col");
      let maxHeight = allCols[0].offsetHeight;

      allCols.forEach((col) => {
        if (maxHeight < col.offsetHeight) maxHeight = col.offsetHeight;
      });
      this.display.colHeight = maxHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-card__title {
  word-break: break-word;
}
</style>
