<template lang="pug">
    .section.cheatsheet#top.white
        GrammarSectionNavigation
        GrammarIntro(showSectionNav)
            template(v-slot:title)
                h1 Cheat sheet to help you learn Articles (a/an/the)
            template(v-slot:content)
                .large
                    p Speaking without articles (a/an/the) in English immediately identifies you as a beginner.
                    v-card.pa-5.my-5(flat color="grey lighten-3")
                      v-row
                        v-col(cols="2" md="1").text-center
                          v-icon(x-large).mb-3 mdi-information
                        v-col(cols="10" md="11")
                          p
                            b Articles are a type of English <a href="https://grammar.yourdictionary.com/parts-of-speech/nouns/what/what-is-a-determiner.html" target="_blank">determiner</a>.
                          p.mb-0 A determiner is a word that introduces a noun. Determiners, such as articles (a/an/the), are required with singular nouns. Determiners are optional for plural nouns.
                    
                    p Determiners always comes before a noun, not after, and they also come before any other adjectives used to describe the noun (e.g. 'a red car', 'my blue notebook').
                    p One of the most common determiners are articles (a/an/the) and there are dozens of rules for when to put A/AN or THE before a noun.
                    p We prepared two ways for you to study English Articles.

        v-container.pb-12
          v-fade-transition
            v-row(justify="center", align="stretch" v-if="!activeComponent")
              v-col.equal-height-card
                v-card.mx-auto(outlined tile max-width="500")
                  v-img(src="@/assets/grammar/rules-warning-sign.jpg" @click="setActiveComponent('RULES')", @load="adjustSizing")
                  v-card-title
                    h2 Article Rules
                  v-card-text
                    p.large Learn English articles (a/an/the) by reviewing each of the {{articlesList.length}} rules we have written out, with example sentences to help you understand.
                  v-card-actions
                    v-btn.font-weight-bold(large color="primary" depressed block @click="setActiveComponent('RULES')")
                      | Review Article Rules
                      v-icon.ml-2 mdi-format-list-numbered
              v-col.equal-height-card
                v-card.mx-auto(outlined tile max-width="500")
                  v-img(src="@/assets/grammar/flow-chart.jpg" @click="setActiveComponent('FLOWCHART')", @load="adjustSizing")
                  v-card-title
                    h2 Flowchart
                  v-card-text
                    p.large Learn English articles by creating your own example sentences and following our "flowchart" to decide which article (a/an/the/none) it needs.

                  v-card-actions
                    v-btn.font-weight-bold(large color="primary" depressed block @click="setActiveComponent('FLOWCHART')")
                      | Review Article Flowchart
                      v-icon.ml-2 mdi-sitemap

          v-expand-transition
            v-component(:is="activeComponent", :key="displayKey" :sentenceToAnalyze="sentenceToAnalyze" @goBack="doGoBack" @analyze='doAnalyze')

</template>

<script>
import { articlesList } from "@/lib/articlesList.js";

import GrammarIntro from "@/components/Grammar/Intro.vue";
import GrammarSectionNavigation from "@/components/Nav/GrammarSectionNavigation.vue";
import Flowchart from "./SubComponents/Flowchart.vue";
import Rules from "./SubComponents/Rules.vue";
import { scrollToTarget } from "@/helpers";

export default {
  name: "Cheatsheet",
  components: { GrammarIntro, GrammarSectionNavigation },
  data() {
    return {
      articlesCheckSentence: null,
      articleCheckResults: [],
      loading: false,
      activeComponent: null,
      articlesList: [],
      displayKey: 0,
      sentenceToAnalyze: null,
      display: {
        cardHeight: null,
      },
    };
  },
  watch: {
    $route() {
      this.adjustSizing();
    },
  },
  created() {
    this.articlesList = articlesList;
  },
  mounted() {
    this.adjustSizing();
    this.$nextTick(() => {
      setTimeout(() => {
        this.adjustSizing();
      }, 1500);
    });
  },
  methods: {
    adjustSizing() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        //   equalize card heights
        const allCards = document.querySelectorAll(".equal-height-card");
        let maxHeight = allCards[0].offsetHeight;

        allCards.forEach((card) => {
          if (maxHeight < card.offsetHeight) maxHeight = card.offsetHeight;
        });

        this.display.cardHeight = maxHeight;
      }
    },
    doGoBack() {
      this.activeComponent = null;
      this.$nextTick(() => {
        scrollToTarget(document.getElementById("top"));
      });
    },
    doAnalyze(e) {
      scrollToTarget(document.getElementById("top"));
      this.sentenceToAnalyze = e;
      this.setActiveComponent("FLOWCHART");
    },
    setActiveComponent(type = "FLOWCHART") {
      this.displayKey++;
      if (type === "FLOWCHART") this.activeComponent = Flowchart;
      if (type === "RULES") this.activeComponent = Rules;
    },
  },
};
</script>
