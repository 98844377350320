<template lang="pug">
    .rules.mb-12(v-if="rules && rules.length > 0")
        .back-button.mb-12(href="#" @click.prevent="$emit('goBack')")
            v-icon.mr-2(color="primary") mdi-chevron-double-left
            em Back to Cheat Sheet
        h2.text-center.text-uppercase.mb-6 {{rules.length}} Rules of English Articles (a/an/the)

        v-container
            .rule.mb-12(v-for="rule, ruleIdx in rules", :key="ruleIdx")
                h2.mt-3 {{ruleIdx + 1}}. {{rule.explanation}}
                p.large.example.mt-3.ml-10
                    v-icon.mr-2.ml-minus-2 mdi-arrow-right
                    em.mr-1 e.g.
                    | {{rule.example}}
                    br
                    a(href="#" @click.prevent="$emit('analyze', rule.example)")
                        em
                            small Analyze this sentence

</template>

<script>
import { articlesList } from "@/lib/articlesList.js";
import { randomFromArray } from "@/helpers";
export default {
  name: "ArticleRules",
  data() {
    return {
      rules: [],
    };
  },
  created() {
    const res = articlesList.map((itm) => {
      return {
        explanation: itm.explanation,
        example: randomFromArray(itm.questions, 1)[0].correct,
      };
    });

    this.rules = res;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/colors.scss";
.back-button {
  cursor: pointer;
  color: $primary;
}

.back-button:hover {
  background: lightyellow;
}
.ml-minus-2 {
  margin-left: -2rem;
}
</style>
