<template lang="pug">
    .test
      GrammarSectionNavigation
      GrammarIntro
        template(v-slot:title)
          h1 Test :: articles (a/an/the)
        template(v-slot:content)
          .large
              p We have prepared {{items.length}} questions to test your understanding of articles (i.e. a/an/the).
              p Some of these test questions are easy, but some are not.
              p If you find these questions difficult, you can
                a.font-weight-bold.mx-2(href="#", @click.prevent="grammarSectionNav({lesson:'articles', section: 'practice'})") try the practice section
                | again until you are more confident.
              p
                strong Note: every time you try this test the questions will change a little bit. You can try it multiple times.

      v-container.mt-6.white.pa-5
        //- debug answers = {{answers}}
        .questions(ref="questions")
            h2.mb-6 Questions 1 ~ {{items.length}}
            .question(v-for="itm, itmIdx in items", :key="'select-the-article-' + itmIdx + '-try-num-' + tryNum")
                SelectTheArticle(:correct="itm.question.correct", :incorrect="itm.question.incorrect", :explanation="itm.explanation" :key="'select-the-article-' + itmIdx + '-question'", :questionNum="itmIdx+1", :showAnswer="showResults" @answered="$set(answers, itmIdx+1, $event)")

        v-btn.font-weight-bold(x-large block color="success" v-if="!showResults" @click="doShowResults")
            v-icon.mr-1 mdi-medal
            | Check your results

        v-expand-transition
            TestResults(ref="results" v-if="showResults", :numCorrect="numCorrect",  :totalQuestions="items.length", @checkAnswers="doCheckAnswers")

</template>

<script>
import { call } from "vuex-pathify";
import GrammarSectionNavigation from "@/components/Nav/GrammarSectionNavigation.vue";
import GrammarIntro from "@/components/Grammar/Intro.vue";
import TestResults from "@/components/Tools/TestResults.vue";
import SelectTheArticle from "@/components/Tools/Articles/SelectTheArticle.vue";
import { articlesList } from "@/lib/articlesList.js";
import {
  randomFromArray,
  scrollToTarget,
  waitForElemToExist,
} from "@/helpers.js";
export default {
  name: "Test",
  components: {
    GrammarSectionNavigation,
    GrammarIntro,
    SelectTheArticle,
    TestResults,
  },
  data() {
    return {
      tryNum: 0,
      items: [],
      answers: {},
      showResults: false,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    numCorrect() {
      if (!this.answers) return 0;
      return Object.values(this.answers).filter(
        (answer) => answer.correct === true
      ).length;
    },
  },
  methods: {
    ...call({ grammarSectionNav: "Nav/grammarSectionNav" }),
    init() {
      const allItems = [];

      articlesList.forEach((itm) => {
        itm.questions.forEach((question) => {
          allItems.push({
            explanation: itm.explanation,
            question,
            type: itm.type,
          });
        });
      });

      let subset = allItems;
      const setOne = allItems.filter((itm) => itm.type < 5);
      const setTwo = allItems.filter((itm) => itm.type >= 5 && itm.type < 17);
      const setThree = allItems.filter((itm) => itm.type >= 17);

      subset = [
        ...randomFromArray(setOne, 2),
        ...randomFromArray(setTwo, 5),
        ...randomFromArray(setThree, 13),
      ];
      this.items = subset;
    },
    async doShowResults() {
      this.showResults = true;

      await waitForElemToExist(".test-results");

      setTimeout(() => {
        scrollToTarget(this.$refs.results.$el, 0);
      }, 500);
    },
    doCheckAnswers() {
      this.showResults = true;

      scrollToTarget(this.$refs.questions, 150);
    },
  },
};
</script>
