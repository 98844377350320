<template lang="pug">
 .video-resource
  v-card.pa-5(:style="{minHeight: $vuetify.breakpoint.mdAndUp ? display.colHeight + 'px' : null}")
   v-card-title.pa-0.mb-3
       h2.mb-3 {{title}}
   v-card-text.pa-0.md-0
       Youtube(:id="videoId", :title="title", :containerWidth="display.containerWidth")
</template>

<script>
import Youtube from "@/components/Youtube.vue";
export default {
  name: "VideoResource",
  components: { Youtube },
  props: {
    title: { type: String, required: true },
    videoId: { type: String, required: true },
    display: { type: Object, required: true },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-card__title {
  word-break: break-word;
}
</style>
