export const articlesList = [
  {
    type: 1,
    explanation:
      "The article A is used before singular, countable nouns which begin with consonant sounds.",
    questions: [
      {
        correct: "He is a doctor.",
        incorrect: "He is doctor.",
      },
      {
        correct: "She doesn't have a computer.",
        incorrect: "She doesn't have computer.",
      },
      {
        correct: "Yesterday I found a wallet.",
        incorrect: "Yesterday I found wallet.",
      },
    ],
  },
  {
    type: 2,
    explanation:
      "The article AN is used before singular, countable nouns which begin with vowel sounds.",
    questions: [
      {
        correct: "He is an accountant.",
        incorrect: "He is accountant.",
      },
      {
        correct: "She didn't see an elephant in Africa.",
        incorrect: "She didn't see elephant in Africa.",
      },
      {
        correct: "I didn't receive an email message from you.",
        incorrect: "I didn't receive email message from you.",
      },
    ],
  },
  {
    type: 3,
    explanation:
      'Remember that a/an means "one" or "a single". You cannot use a/an with plural nouns.',
    questions: [
      {
        correct: "There are flowers at my grandmother's house",
        incorrect: "There are [] flowers at my grandmother's house",
      },
      {
        correct: "I have two brothers and a sister",
        incorrect: "I have [] two brothers and sister",
      },
      {
        correct: "My cousin and I saw three movies together recently.",
        incorrect: "My cousin and I saw [] three movies together [] recently.",
      },
    ],
  },
  {
    type: 4,
    explanation:
      "If there is an adjective or an adverb-adjective combination before the noun, a/an should agree with the first sound in the adjective or the adverb-adjective combination.",
    questions: [
      {
        correct: "He is an excellent lawyer",
        incorrect: "He is excellent lawyer",
      },
      {
        correct: "I saw a really beautiful elephant in Thailand.",
        incorrect: "I saw really beautiful elephant in Thailand.",
      },
      {
        correct:
          "One famous artist, Van Gogh, painted himself with a bandaged ear.",
        incorrect:
          "One famous artist, Van Gogh, painted himself with bandaged ear.",
      },
    ],
  },
  {
    type: 5,
    explanation:
      "Remember, it is the sound, not the spelling, which is important. Use 'A' before words that *sound* like they start with a consonant, even if they don't. (e.g. 'university', 'one', etc).",
    questions: [
      {
        correct: "She has a Euro bank account and a Dollar bank account.",
        incorrect: "She has Euro bank account and Dollar bank account.",
      },
      {
        correct: "Many students are required to wear a uniform to school.",
        incorrect: "Many students are required to wear uniform to school.",
      },
      {
        correct:
          "Before this building was a church. Now it is a university building.",
        incorrect:
          "Before this building was church. Now it is university building.",
      },
      {
        correct: "My cousin became a US citizen last year.",
        incorrect: "My cousin became US citizen last year.",
      },
    ],
  },
  {
    type: 6,
    explanation:
      "Remember, it is the sound, not the spelling, which is important. Use 'An' before words that *sound* like they start with a vowel, even if they don't. (e.g. 'hour', 'honest', etc).",
    questions: [
      {
        correct: "They say it is impossible to cheat an honest man.",
        incorrect: "They say it is impossible to cheat honest man.",
      },
      {
        correct:
          "The king wants to get married because it is important for him to have an heir.",
        incorrect:
          "The king wants to get married because it is important for him to have heir.",
      },
      {
        correct: "Can a criminal be an honorable man sometimes?",
        incorrect: "Can a criminal be honorable man sometimes?",
      },
    ],
  },
  {
    type: 7,
    explanation: "We do not use a/an with uncountable nouns.",
    questions: [
      {
        correct: "I need to ask you for information about my mother.",
        incorrect: "I need to ask you for [] information about my mother.",
      },
      {
        correct: "Where can I get advice about my problem?",
        incorrect: "Where can I get [] advice about my problem?",
      },
      {
        correct: "Can you give me feedback about this website I made?",
        incorrect: "Can you give me [] feedback about this website I made?",
      },
      {
        correct: "Whenever I visit my friend's house we always have fun.",
        incorrect: "Whenever I visit my friend's house we always have [] fun.",
      },
    ],
  },
  {
    type: 8,
    explanation:
      'A and An are called indefinite articles. "Indefinite" means "not specific". Use a/an when you are talking about a thing in general, NOT a specific thing.',
    questions: [
      {
        correct: "Samantha wants to buy a phone.",
        incorrect: "Samantha wants to buy phone.",
      },
      {
        correct: "Do you have a driver's license?",
        incorrect: "Do you have driver's license?",
      },
      {
        correct: "Excuse me, do you have a cigarrete?",
        incorrect: "Excuse me, do you have cigarrete?",
      },
    ],
  },
  {
    type: 9,
    explanation:
      "Use a/an when talking about a thing which is new, unknown, or introduced to a listener for the first time.",
    questions: [
      {
        correct: "I have a Toyota",
        incorrect: "I have Toyota",
      },
      {
        correct: "Andrew is a programmer",
        incorrect: "Andrew is programmer",
      },
      {
        correct: "Oh, that's a new law this year",
        incorrect: "Oh, that's new law this year",
      },
    ],
  },
  {
    type: 10,
    explanation:
      "Use a/an when you are asking about the existence of something.",
    questions: [
      {
        correct: "Do you have an extra battery in your backpack?",
        incorrect: "Do you have extra battery in your backpack?",
      },
      {
        correct: "Is there a reason you're upset?",
        incorrect: "Is there reason you're upset?",
      },
      {
        correct: "Is there a rocket that can go to Mars?",
        incorrect: "Is there rocket that can go to [] Mars?",
      },
    ],
  },
  {
    type: 11,
    explanation:
      "Use a/an to introduce what type of thing we are talking about.",
    questions: [
      {
        correct: "'The Matrix' is an excellent movie.",
        incorrect: "'The Matrix' is excellent movie.",
      },
      {
        correct: "Do you work in a busy office or a quiet office?",
        incorrect: "Do you work in busy office or quiet office?",
      },
      {
        correct: "I ate a delicious blueberry pie.",
        incorrect: "I ate delicious blueberry pie.",
      },
    ],
  },
  {
    type: 12,
    explanation:
      'THE is called a definite article. "Definite" means "specific". Use "the" when talking about something which is already known to the listener or which has been previously mentioned, introduced, or discussed.',
    questions: [
      {
        correct: "I have a laptop. The laptop is new.",
        incorrect: "I have laptop. laptop is new.",
      },
      {
        correct: "There is a phone in my pocket. The phone is black.",
        incorrect: "There is phone in my pocket. phone is black.",
      },
      {
        correct: "I decided to get a tattoo on the other leg.",
        incorrect: "I decided to get a tattoo on other leg.",
      },
      {
        correct: "Do you own a smartphone? Is the phone new?",
        incorrect: "Do you own smartphone? Is phone new?",
      },
      {
        correct: "Does anybody live on the moon?",
        incorrect: "Does anybody live on moon?",
      },
    ],
  },
  {
    type: 13,
    explanation: 'You can use "the" with both singular and plural nouns.',
    questions: [
      {
        correct:
          "I saw all the students from your class at the mall yesterday.",
        incorrect: "I saw all students from your class at mall yesterday.",
      },
      {
        correct:
          "The workers at the post office near my house are all rude to me.",
        incorrect: "workers at post office near my house are all rude to me.",
      },
      {
        correct: "I can't understand the reasons for my relationship problems",
        incorrect: "I can't understand reasons for my relationship problems",
      },
    ],
  },
  {
    type: 14,
    explanation:
      'You can use "the" to make the noun known to the listener, by telling the listener which person or thing we are talking about.',
    questions: [
      {
        correct: "Please give me the big, blue book from my backpack.",
        incorrect: "Please give me big, blue book from my backpack.",
      },
      {
        correct: "I couldn't finish the book that you suggested.",
        incorrect: "I couldn't finish book that you suggested.",
      },
      {
        correct: "Do you know the woman who was talking to my husband?",
        incorrect: "Do you know woman who was talking to my husband?",
      },
      {
        correct: "See those cars? The blue one is mine.",
        incorrect: "See those cars? Blue one is mine.",
      },
    ],
  },
  {
    type: 15,
    explanation:
      'Use "the" when the listener knows which specific item you are describing. Use "a" when it could be one of many options.',
    questions: [
      {
        correct: "I bought the phone with the most memory",
        incorrect: "I bought phone with most memory",
      },
      {
        correct: "She wanted a house with nice windows",
        incorrect: "She wanted house with nice windows",
      },
      {
        correct: "Can you recommend a movie starring Brad Pitt?",
        incorrect: "Can you recommend movie starring Brad Pitt?",
      },
    ],
  },
  {
    type: 16,
    explanation: 'A/an can be used like the word "per".',
    questions: [
      {
        correct: "These oranges cost $1 a kilo.",
        incorrect: "These oranges cost $1 kilo.",
      },
      {
        correct: "Did you know a house cat can run 48 km an hour?",
        incorrect: "Did you know a house cat can run 48 km hour?",
      },
      {
        correct: "Where can we get dinner for $15 a person?",
        incorrect: "Where can we get dinner for $15 person?",
      },
    ],
  },
  {
    type: 17,
    explanation:
      'Use THE with nouns modified by ranking or ordering expressions such as "the first", "the second", "the third", "the next", "the last", "the previous", "the following", "the ultimate", etc.',
    questions: [
      {
        correct: "This is the fifth day since I got sick.",
        incorrect: "This is fifth day since I got sick.",
      },
      {
        correct:
          "The next time I see you in this bar we're going to have a problem.",
        incorrect:
          "Next time I see you in this bar we're going to have a problem.",
      },
      {
        correct: "Don't forget the final rule!",
        incorrect: "Don't forget final rule!",
      },
    ],
  },
  {
    type: 18,
    explanation:
      'Use THE with superlatives such as "the best", "the biggest", "the most important", "the least interesting", etc.',
    questions: [
      {
        correct: "Thank you! This was the best day of my life.",
        incorrect: "Thank you! This was best day of my life.",
      },
      {
        correct: "That's the best joke I've heard in a long time.",
        incorrect: "That's best joke I've heard in a long time.",
      },
      {
        correct:
          "I like Apple products, but it's true they're the most expensive.",
        incorrect:
          "I like Apple products, but it's true they're most expensive.",
      },
    ],
  },
  {
    type: 19,
    explanation:
      'Comparative forms, such as "bigger", "better", "more" can be used with both "a/an" and "the".',
    questions: [
      {
        correct: "The faster you drive, the more danger there is.",
        incorrect: "faster you drive, more danger there is.",
      },
      {
        correct: "She has a nicer kitchen than ours.",
        incorrect: "She has nicer kitchen than ours.",
      },
      {
        correct: "I wish we could afford a better car.",
        incorrect: "I wish we could afford better car.",
      },
    ],
  },
  {
    type: 20,
    explanation:
      '"The" is often used with comparative forms (e.g. bigger) rather than superlative forms (e.g. biggest) when comparing only TWO things.',
    questions: [
      {
        correct:
          "Naomi and Daniel are both smart, but I think Daniel is the smarter of the two.",
        incorrect:
          "Naomi and Daniel are both smart, but I think Daniel is smarter of two.",
      },
      {
        correct:
          "Your son is an excellent runner, but your daugher is the real athlete.",
        incorrect:
          "Your son is an excellent runner, but your daugher is real athlete.",
      },
    ],
  },
  {
    type: 20,
    explanation:
      "Do not use articles when generalizing about uncountable nouns and plural countable nouns.",
    questions: [
      {
        correct: "Experience is important, but wisdom is what matters.",
        incorrect: "Experience is important, but [] wisdom is what matters.",
      },
      {
        correct: "Without water, there can be no life.",
        incorrect: "Without [] water, there can be no life.",
      },
      {
        correct: "We always eat apples to stay healthy.",
        incorrect: "We always eat [] apples to stay healthy.",
      },
    ],
  },
  {
    type: 21,
    explanation:
      'We often use "the" plus a singular noun when we talk about or make generalizations about topics like musical instruments, plants, animals, inventions, currencies, and body parts. We use "the" in this way to give the noun a more abstract or conceptual sound.',
    questions: [
      {
        correct: "Oh you can play the guitar! How romantic!",
        incorrect: "Oh you can play guitar! How romantic!",
      },
      {
        correct: "The African elephant is the largest land mammal",
        incorrect: "African elephant is largest land mammal",
      },
      {
        correct: "Before the light bulb, people went to sleep much earlier.",
        incorrect: "Before light bulb, people went to sleep much earlier.",
      },
      {
        correct: "The dollar is stronger this year than it was last year.",
        incorrect: "dollar is stronger this year than it was last year.",
      },
      {
        correct: "The human heart is an incredible machine.",
        incorrect: "human heart is incredible machine.",
      },
      {
        correct:
          "How would you travel if the airplane had never been invented?",
        incorrect: "How would you travel if airplane had never been invented?",
      },
      {
        correct: "The last thing I remember is getting hit in the eye.",
        incorrect: "The last thing I remember is getting hit in eye.",
      },
    ],
  },
  {
    type: 21,
    explanation:
      'The expressions "a few" and "a little" mean "some" and express the idea that you have more than expected.',
    questions: [
      {
        correct:
          "I always have a few extra dollars in my pocket for emergencies",
        incorrect:
          "I always have few extra dollars in my pocket for emergencies",
      },
      {
        correct: "I almost finished my project, but I had a little difficulty.",
        incorrect: "I almost finished project, but I had a little difficulty.",
      },
      {
        correct: "Don't worry: I have a few friends who can take care of me.",
        incorrect: "Don't worry: I have few friends who can take care of me.",
      },
    ],
  },
  {
    type: 22,
    explanation:
      'The expressions "few" and "little" (without an article) mean "not much" and express the idea that you have less than expected.',
    questions: [
      {
        correct:
          "Life was stressful. When I was young I had little time to play and make friends.",
        incorrect:
          "Life was stressful. When I was young I had [] little time to play and make friends.",
      },
      {
        correct: "Sadly, she has few people she can rely on.",
        incorrect: "Sadly, she has [] few people she can rely on.",
      },
      {
        correct:
          "Since they had little money, they couldn't afford to buy a magazine subscription.",
        incorrect:
          "Since they had [] little money, they couldn't afford to buy magazine subscription.",
      },
    ],
  },
  {
    type: 23,
    explanation:
      'When the words "only" or "just" are used, "a few" and "a little" also emphasize the meaning "not much"',
    questions: [
      {
        correct:
          "Unfortunately, I only had a little candy, so Halloween wasn't great",
        incorrect:
          "Unfortunately, I only had little candy, so Halloween wasn't great",
      },
      {
        correct: "He just has a few dollars. He can't pay us.",
        incorrect: "He just has few dollars. He can't pay us.",
      },
    ],
  },
  {
    type: 24,
    explanation:
      "Generally, articles are not used with the names of illnesses or diseases.",
    questions: [
      {
        correct:
          "These days doctors are learning how to treat AIDS successfully.",
        incorrect:
          "These days doctors are learning how to treat [] AIDS successfully.",
      },
      {
        correct: "Oncologists are doctors who specialize in treating cancer.",
        incorrect:
          "Oncologists are doctors who specialize in treating [] cancer.",
      },
      {
        correct: "There is a vaccine that can prevent Rabies.",
        incorrect: "There is a vaccine that can prevent [] Rabies.",
      },
    ],
  },
  {
    type: 25,
    explanation:
      'People mistakenly think of "the Flu" as one disease, not several. For that reason we use "the".',
    questions: [
      {
        correct: "Maria has been absent for a week. She says she got the flu.",
        incorrect: "Maria has been absent for a week. She says she got flu.",
      },
      {
        correct: "Every year I get a vaccine for the flu, and I hope it works.",
        incorrect: "Every year I get a vaccine for flu, and I hope it works.",
      },
    ],
  },
  {
    type: 26,
    explanation:
      "Aches, pains, growths, and attacks can be used with either a/an or the, depending on the other rules for definite and indefinite articles.",
    questions: [
      {
        correct:
          "My dad had a heart attack, but the doctor said the heart attack wasn't severe.",
        incorrect:
          "My dad had heart attack, but the doctor said heart attack wasn't severe.",
      },
      {
        correct:
          "The vet put a bandage on my dog's leg. The leg had a big cut.",
        incorrect: "vet put bandage on my dog's leg. leg had big cut.",
      },
      {
        correct:
          "I'm confused: I can't tell if this pain is caused by a toothache or a headache.",
        incorrect:
          "I'm confused: I can't tell if this pain is caused by toothache or headache.",
      },
      {
        correct:
          "My boss had a sore throat. The sore throat made it difficult to talk to her.",
        incorrect:
          "My boss had sore throat. sore throat made it difficult to talk to her.",
      },
    ],
  },
  {
    type: 27,
    explanation:
      "If a direction (north, west, southeast, left, right) directly follows a verb, do not use an article with the direction.",
    questions: [
      {
        correct: "We're lost. I think we need to walk East.",
        incorrect: "We're lost. I think we need to walk [] East.",
      },
      {
        correct: "If we keep driving north, we will get there.",
        incorrect: "If we keep driving [] north, we will get there.",
      },
      {
        correct:
          "When you see the Central Post Office, turn left and walk 100 meters.",
        incorrect:
          "When you see Central Post Office, turn [] left and walk 100 meters.",
      },
    ],
  },
  {
    type: 28,
    explanation: 'If a direction follows a preposition, you must use "the".',
    questions: [
      {
        correct: "If facing the bank, the grocery store is on the right.",
        incorrect: "If facing bank, grocery store is on right.",
      },
      {
        correct: "Our cottage is in the north part of the island.",
        incorrect: "Our cottage is in north part of island.",
      },
    ],
  },
  {
    type: 29,
    explanation:
      'Use "the" with compass directions when referring to them as special geographic or cultural regions.',
    questions: [
      {
        correct: "I plan to retire in the South of France.",
        incorrect: "I plan to retire in South of France.",
      },
      {
        correct: "Have you ever traveled to the Far East of Russia?",
        incorrect: "Have you ever traveled to Far East of Russia?",
      },
      {
        correct:
          "They say the East has studied traditional medicine for thousands of years.",
        incorrect:
          "They say East has studied traditional medicine for thousands of years.",
      },
    ],
  },
  {
    type: 30,
    explanation:
      '"The" can be used with plural family names to refer to the family as a group.',
    questions: [
      {
        correct: "The Clooneys always spend their vacation in Italy.",
        incorrect: "Clooneys always spend their vacation in Italy.",
      },
      {
        correct: "The Volkovs are coming over for dinner next Friday.",
        incorrect: "Volkovs are coming over for dinner next Friday.",
      },
      {
        correct: "Our family has known the Johnsons for over 60 years.",
        incorrect: "Our family has known Johnsons for over 60 years.",
      },
    ],
  },
  {
    type: 30,
    explanation:
      '"The" can be combined with certain adjectives to refer to a group of people, such as "the blind".',
    questions: [
      {
        correct:
          "During times of national stress, we can't forget about the elderly.",
        incorrect:
          "During times of national stress, we can't forget about elderly.",
      },
      {
        correct:
          "Ray Kurzweil is famous for creating a reading machine for the blind.",
        incorrect:
          "Ray Kurzweil is famous for creating reading machine for blind.",
      },
      {
        correct: "There was a struggle between the rich and the poor.",
        incorrect: "There was struggle between rich and poor.",
      },
    ],
  },
  {
    type: 31,
    explanation:
      '"The" is used to specify that we are talking about the nationality or ethnic group rather than the language.',
    questions: [
      {
        correct: "The French and the British fought for many years.",
        incorrect: "French and British fought for many years.",
      },
      {
        correct:
          "The Germans were hoping that german would become the number 1 language.",
        incorrect:
          "Germans were hoping that german would become number 1 language.",
      },
    ],
  },
  {
    type: 32,
    explanation:
      'When generalizing about nationalities or ethnic groups that end in "-ans", such as "Americans", "Mexicans", etc, "the" is not usually used.',
    questions: [
      {
        correct: "Americans value free speech.",
        incorrect: "[] americans value free speech.",
      },
      {
        correct:
          "Belarusians speak Russian, but they also have their own language.",
        incorrect:
          "[] Belarusians speak Russian, but they also have their own language.",
      },
    ],
  },
  {
    type: 33,
    explanation:
      'Do not use "the" with the names of most countries, states, provinces, cities, etc, unless the name contains a word such as "States", "Kingdom", "Republic", "Emirates", "Union", "Coast", etc.',
    questions: [
      {
        correct:
          "The United States produces the most expensive films in the world.",
        incorrect: "United States produces most expensive films in world.",
      },
      {
        correct: "I love traveling around Italy when the weather gets cooler.",
        incorrect: "I love traveling around [] Italy when weather gets cooler.",
      },
      {
        correct:
          "Wine from California is popular all over the United States and Canada.",
        incorrect:
          "Wine from [] California is popular all over United States and [] Canada.",
      },
    ],
  },
  {
    type: 34,
    explanation:
      '"The" is used with "the Netherlands" as well as with many nations which are island chains, such as "the Philippines".',
    questions: [
      {
        correct:
          "We canceled our trip to the Bahamas because of the Coronavirus.",
        incorrect: "We canceled our trip to Bahamas because of Coronavirus.",
      },
      {
        correct: "He lived in the Netherlands before he got married.",
        incorrect: "He lived in Netherlands before he got married.",
      },
    ],
  },
  {
    type: 34,
    explanation:
      'Use "the" with the names of: oceans, rivers, deserts, hotels, theaters, museums, institutes, and works of art.',
    questions: [
      {
        correct:
          "When I saw the Mona Lisa at the Louvre (museum), I wasn't impressed.",
        incorrect:
          "When I saw Mona Lisa at Louvre (museum), I wasn't impressed.",
      },
      {
        correct: "I would love to visit the Amazon rainforest some day.",
        incorrect: "I would love to visit Amazon rainforest some day.",
      },
      {
        correct:
          "I love traveling in the Gobe desert. It's completely different from the Sahara desert.",
        incorrect:
          "I love traveling in Gobe desert. It's completely different from Sahara desert.",
      },
    ],
  },
  {
    type: 35,
    explanation:
      "Do not use an article with: beaches, waterfalls, people's names, hospitals, churches, universities, religions, days, months, holidays...",
    questions: [
      {
        correct: "Did you know James is Buddhist? He went to Tibet last year.",
        incorrect:
          "Did you know James is [] Buddhist? He went to [] Tibet last year.",
      },
      {
        correct: "My niece studies at Harvard.",
        incorrect: "My niece studies at [] Harvard.",
      },
      {
        correct: "I'm busy this week. Can we meet on Monday next week?",
        incorrect: "I'm busy this week. Can we meet on [] Monday next week?",
      },
    ],
  },
  {
    type: 36,
    explanation:
      '"The" is used in some time expressions such as: "in the _____", "during the _____", and the [season]...',
    questions: [
      {
        correct:
          "After 9 hours of labor, she finally delivered a healthy baby in the morning.",
        incorrect:
          "After 9 hours of [] labor, she finally delivered baby in morning.",
      },
      {
        correct: "There was a large earthquake during the night.",
        incorrect: "There was a large earthquake during night.",
      },
      {
        correct: "I love to drive around Italy in the fall.",
        incorrect: "I love to drive around Italy in fall.",
      },
    ],
  },
  {
    type: 37,
    explanation:
      "In some time expressions, no article is used: e.g. at [time], all [time], every [time], last [time], yesterday, tomorrow...",
    questions: [
      {
        correct: "I couldn't sleep last night!",
        incorrect: "I couldn't sleep [] last night!",
      },
      {
        correct: "Let's meet for lunch at 3pm.",
        incorrect: "Let's meet for lunch at [] 3pm.",
      },
      {
        correct: "We play a friendly game of Volleyball every year.",
        incorrect: "We play friendly game of Volleyball [] every year.",
      },
    ],
  },
];
