<template>
  <iframe
    :width="maxWidth"
    height="315"
    :src="'https://www.youtube.com/embed/' + id"
    :srcdoc="srcDoc"
    frameborder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
    :title="title"
  ></iframe>
</template>

<script>
export default {
  name: "LazyYoutube",
  props: {
    id: { type: String, required: true },
    title: { type: String, default: "JikJi Pro" },
    coverImageSrc: { type: String, default: null },
    containerWidth: { type: Number, default: parseInt(screen.width * 0.45) },
  },
  data() {
    return {};
  },

  computed: {
    maxWidth() {
      return this.containerWidth;
    },
    coverImage() {
      if (this.coverImageSrc) {
        return this.coverImageSrc;
      } else {
        return `https://img.youtube.com/vi/${this.id}/hqdefault.jpg`;
      }
    },
    srcDoc() {
      return `<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.15em black}</style><a href=https://www.youtube.com/embed/${this.id}?autoplay=1><img src=${this.coverImage} alt='${this.title}'><span>▶</span></a>`;
    },
  },
};
</script>
