<template lang="pug">
    .flowchart.mb-12
        .back-button.mb-12(href="#" @click.prevent="$emit('goBack')")
          v-icon.mr-2(color="primary") mdi-chevron-double-left
          em Back to Cheat Sheet

        v-container.grey.lighten-4.pa-5.pa-md-12
          h2.text-center.text-uppercase.mb-6 How to decide which article (A/AN or THE) to use
          p.large We created a "flowchart" to help you check which article to use in a sentence.
          p.large Start by typing a sentence you're thinking about. (You can include the article or not.)
          
          v-text-field#textField(v-model="sentence", hide-details, outlined, x-large, placeholder="He is famous actor", clearable)
          v-btn.mt-3(large outlined color="primary" :block="$vuetify.breakpoint.smAndDown" :disabled="!sentence || sentence.split(' ').length < 3" @click="doAnalysis(sentence)").flex-grow-1 Analyze this sentence
          v-btn.mt-3.ml-0.ml-md-3(large outlined color="primary" :block="$vuetify.breakpoint.smAndDown" :disabled="words && words.length > 0" @click="doExample").flex-shrink-1 Try an example

          template(v-if="words && words.length > 0")
            v-divider.mt-3.mb-3
            h2 Words in this sentence
            p Click on one of these words to analyze whether it needs an article

            #words.words.mt-3.mb-6
                v-chip.word.mr-3.mb-2(v-for="word, wordIdx in words", :key="wordIdx" @click="doClickWord($event.target.textContent)").text-uppercase {{word}}

          v-timeline(:align-top="true", :dense="$vuetify.breakpoint.smAndDown", v-if="word")
              v-timeline-item
                  v-card
                      v-card-subtitle.text-left.text-md-right
                        em "{{sentence}}"
                      v-card-title.headline.d-inline-block Is <b>{{word}}</b> a noun?
                      v-card-text
                          p We only use articles with nouns, but if the noun has an adjective in front, we put the article first, like "a good dog" or "the big house".
                          v-btn(small, outlined color="success" @click="doNextStep('is_noun')" block) Yes, it's a noun
                          v-btn.mt-2(small, outlined color="warning" @click="doShowResult('NO_ARTICLE')" block) No, it's not

              v-timeline-item(v-if="checkAttributes('is_noun')")
                  v-card
                      v-card-subtitle.text-left.text-md-right
                        em "{{sentence}}"
                      v-card-title.headline.d-inline-block In this sentence, should you put a possessive in front of <b>{{word}}</b>?
                      v-card-text
                          p The English language likes to use "determiners". Determiners are words that "determine" or "tell" or "limit" what we are talking about.
                          p One of the most common types of determiners are "possessives", like: <b>"my", "your", "his", "her", "its", "our", and "their"</b>.
                          p Another version of possessives is apostrophe + 's', such as "This is Alex<b>'s</b> brother" or "That is Alex</b>'s</b> car".
                          p If you use one of these possessives, you don't need an article (neither 'the' nor 'a/an') because that would be a second determiner.
                          p Can you put a possessive, like "my {{word.toLowerCase()}}" or "her {{word.toLowerCase()}}" in front of <b>{{word}}</b>?
                          v-btn(small, outlined color="success" @click="doShowResult('POSSESSIVE')" block) Yes, a possessive is ok
                          v-btn.mt-2(small, outlined color="warning" @click="doNextStep('not_possessive')" block) No possessive needed

              v-timeline-item(v-if="checkAttributes('not_possessive')")
                  v-card
                      v-card-subtitle.text-left.text-md-right
                        em "{{sentence}}"
                      v-card-title.headline.d-inline-block Is <b>{{word}}</b> a proper noun (like somebody's name)?
                      v-card-text
                          p For proper nouns, like names (e.g. "Michael", "Obama", "Canada"), we don't use any article, because they're already completely determined.
                          v-btn(small, outlined color="success" @click="doNextStep('is_proper_noun')" block) Yes, it's a proper noun
                          v-btn.mt-2(small, outlined color="warning" @click="doNextStep('not_proper_noun')" block) No, it's not

              v-timeline-item(v-if="checkAttributes('is_proper_noun')")
                  v-card
                      v-card-subtitle.text-left.text-md-right
                        em "{{sentence}}"
                      v-card-title.headline.d-inline-block Is <b>{{word}}</b> an ocean, river, sea, waterfall, mountain chain, desert, or specific geographic name (e.g. 'The USA', 'The Netherlands', etc)?
                      v-card-text
                          p Some geography, like the names of ALL oceans, ALL rivers, ALL seas, ALL waterfalls, ALL mountain chains, most deserts, some countries, some individual mountains, highways, etc always requires the definite article, <em>the</em>.
                          p It is important to know the difference between examples like "America" (proper noun, not a special geographic name, no article) and "The USA" (a special geographic name, needs 'the').
                          p Another example is words like "Republic", "Kingdom", "Federation", 'Commonwealth', etc. These always require the definite article, <em>the</em>, such as "the Kingdom of Spain" or "the Republic of Belarus".
                          v-expansion-panels.mt-3.mb-6(flat)
                            v-expansion-panel
                              v-expansion-panel-header Press here for a list of examples
                              v-expansion-panel-content
                                ul
                                  li(v-for="geographicItm, geographicItmIdx in definiteArticleGeography", :key="geographicItmIdx") {{geographicItm}}

                          v-btn(small, outlined color="success" @click="doShowResult('DEFINITE_ARTICLE')" block) Yes, it's in these groups
                          v-btn.mt-2(small, outlined color="warning" @click="doNextStep('not_special_definite_article')" block) No, it's not

              v-timeline-item(v-if="checkAttributes('not_special_definite_article')")
                  v-card
                      v-card-subtitle.text-left.text-md-right
                        em "{{sentence}}"
                      v-card-title.headline.d-inline-block Is <b>{{word}}</b> a continent, a named country, a state, a province, a lake, a named island, or a named street?
                      v-card-text
                          p We do not use any article for most names, such as names of countries, states, provinces, lakes, island, and named streets.
                          p For example, many cities have a street called "Main Street". We do not say 'a Main Street' or 'the Main Street' because the street's name is "Main Street". So we say, "I'll meet you on Main Street".

                          v-expansion-panels.mt-3.mb-6(flat)
                            v-expansion-panel
                              v-expansion-panel-header Press here for a list of examples
                              v-expansion-panel-content
                                ul
                                  li(v-for="geographicItm, geographicItmIdx in noArticleGeography", :key="geographicItmIdx") {{geographicItm}}

                          v-btn(small, outlined color="success" @click="doShowResult('NO_ARTICLE')" block) Yes, it is
                          v-btn.mt-2(small, outlined color="warning" @click="doNextStep('not_geography')" block) No, it's not

              v-timeline-item(v-if="checkAttributes('not_geography', 'not_proper_noun')")
                  v-card
                      v-card-subtitle.text-left.text-md-right
                        em "{{sentence}}"
                      v-card-title.headline.d-inline-block Is <b>{{word}}</b> described by a 'superlative' or 'ordinal' word, like 'best', 'most', 'first', 'next', 'last', etc?
                      v-card-text
                          p We always use the definite article, <em>the</em>, before superlatives (e.g. 'best', 'most', 'happiest', 'prettiest', etc). We also use it before ordinal or "order" words, like "first", 'second', 'next', 'last', etc.
                          p For example, "the smartest student in our class", "the best movie this year", "the last bus tonight", etc

                          v-btn(small, outlined color="success" @click="doShowResult('SUPERLATIVE')" block) Yes, it is
                          v-btn.mt-2(small, outlined color="warning" @click="doNextStep('not_superlative_nor_ordinal')" block) No, it's not

              v-timeline-item(v-if="checkAttributes('not_superlative_nor_ordinal')")
                  v-card
                      v-card-subtitle.text-left.text-md-right
                        em "{{sentence}}"
                      v-card-title.headline.d-inline-block Is <b>{{word}}</b> an amount PER time / season / duration / distance / count?
                      v-card-text
                          p We use the indefinite article, <em>a/an</em>, when giving numbers PER times or distances.
                          p For example, "I drive 60km *per* hour" = "I drive 60km *an* hour"
                          p Another example: "It costs $1 *per* Kilo" = "It costs $1 *a* Kilo"

                          v-btn(small, outlined color="success" @click="doShowResult('INDEFINITE_ARTICLE')" block) Yes, it's PER something
                          v-btn.mt-2(small, outlined color="warning" @click="doNextStep('not_per')" block) No, it's not

              v-timeline-item(v-if="checkAttributes('not_per')")
                  v-card
                      v-card-subtitle.text-left.text-md-right
                        em "{{sentence}}"
                      v-card-title.headline.d-inline-block Is <b>{{word}}</b> a countable noun?
                      v-card-text
                          p Countable nouns are things that can be counted with numbers. You can ask the question "how many" about them.
                          p For example, you can say <em>"how many cookies did Tom eat?"</em> or <em>"how many cars were sold yesterday?"</em>. Cookies and cars are things we can count.
                          p Some nouns are uncountable. We can't really ask <em>"how many justice?"</em> or <em>"how many wisdom?"</em> because these are uncountable ideas, and therefore uncountable nouns.
                          v-btn(small, outlined color="success" @click="doNextStep('is_countable')" block) Yes, it's countable
                          v-btn.mt-2(small, outlined color="warning" @click="doNextStep('not_countable')" block) No, it's not

              v-timeline-item(v-if="checkAttributes('not_countable')")
                  v-card
                      v-card-subtitle.text-left.text-md-right
                        em "{{sentence}}"
                      v-card-title.headline.d-inline-block Is <b>{{word}}</b> a concrete noun?
                      v-card-text
                          p Concrete nouns are things you can experience with your 5 senses, like taste, smell, touch, hearing, and sight.
                          p For example, <em>'what is the sound of love?'</em>. Sound is something we experience with one of our senses, so it needs an article.
                          v-btn(small, outlined color="success" @click="doNextStep('is_concrete')" block) Yes, it's concrete
                          v-btn.mt-2(small, outlined color="warning" @click="doShowResult('NO_ARTICLE')" block) No, it's not

              v-timeline-item(v-if="checkAttributes('is_countable', 'is_concrete')")
                  v-card
                      v-card-subtitle.text-left.text-md-right
                        em "{{sentence}}"
                      v-card-title.headline.d-inline-block Are you saying <b>{{word}}</b> to refer to something general or specific?
                      v-card-text
                          p An example of something specific or "definite" is <em>"the blue car in front of my house"</em>. In that case you are referring to a specific car.
                          p An example of something general or "indefinite" is <em>"I want to buy a car"</em>. In this case, you are not talking about a specific car. You just mean any car.
                          p Even if you said <em>"I want to buy a big white car with four seats"</em>, it is still not one specific car.
                          v-btn(small, outlined color="success" @click="doNextStep('is_general')" block) {{word}} is General
                          v-btn.mt-2(small, outlined color="warning" @click="doShowResult('DEFINITE_ARTICLE')" block) {{word}} is specific

              v-timeline-item(v-if="checkAttributes('is_general')")
                  v-card
                      v-card-subtitle.text-left.text-md-right
                        em "{{sentence}}"
                      v-card-title.headline.d-inline-block Is <b>{{word}}</b> plural or singular?
                      v-card-text
                          p Plural words are words that usually end in -s, and mean "many". For example, <em>"pencils", "women", "books", etc</em>.
                          p Singular words are countable and mean "only one". For example, <em>"pencil", "woman", "book", etc</em>.
                          v-btn(small, outlined color="success" @click="doShowResult('NO_ARTICLE')" block) {{word}} is plural
                          v-btn.mt-2(small, outlined color="warning" @click="doShowResult('INDEFINITE_ARTICLE')" block) {{word}} is singular

          #result(v-if="word")
              v-card.pa-5(outlined v-if="result")
                  .text-center
                      v-icon(size="4rem") mdi-magnify-scan
                  template(v-if="result === 'NO_ARTICLE'")
                      v-card-title.headline.font-weight-bold No article needed!
                      v-card-text
                          p.large Based on your answers to the flowchart, <b>{{word || 'the word'}}</b> does not need an article in this sentence.
                          v-btn.mb-3(small, outlined @click="doReset" block) Try another one
                          v-btn(small, outlined @click="$emit('goBack')" block, color="warning") Back to Cheat Sheet
                  template(v-if="result === 'DEFINITE_ARTICLE'")
                      v-card-title.headline.font-weight-bold You need the 'Definite Article', THE
                      v-card-text
                          p.large Based on your answers to the flowchart, <b>{{word || 'the word'}}</b> DOES need an article: <mark>THE {{word.toUpperCase()}}</mark>.
                          p.large This is true even for plural words, like "the children" or "the books in my backpack".
                          v-btn.mb-3(small, outlined @click="doReset" block) Try another one
                          v-btn(small, outlined @click="$emit('goBack')", block, color="warning") Back to Cheat Sheet
                  template(v-if="result === 'INDEFINITE_ARTICLE'")
                      v-card-title.headline.font-weight-bold You need the 'Indefinite Article', A/AN
                      v-card-text
                          p.large Based on your answers to the flowchart, <b>{{word || 'the word'}}</b> DOES need an article: <mark>{{getIndefiniteArticle(word).toUpperCase()}}</mark>.
                          v-btn.mb-3(small, outlined @click="doReset" block) Try another one
                          v-btn(small, outlined @click="$emit('goBack')", block, color="warning") Back to Cheat Sheet
                  template(v-if="result === 'POSSESSIVE'")
                      v-card-title.headline.font-weight-bold No article needed because it's possessive!
                      v-card-text
                          p.large Based on your answers to the flowchart, <b>{{word || 'the word'}}</b> DOES NOT need an article. This is because you can use a possessive instead.
                          p.large You can say "MY {{word}}" or "HER {{word}}", instead of saying a/an/the. We never combine possessives and articles at the same time.
                          v-btn.mb-3(small, outlined @click="doReset" block) Try another one
                          v-btn(small, outlined @click="$emit('goBack')", block, color="warning") Back to Cheat Sheet
                  template(v-if="result === 'SUPERLATIVE'")
                      v-card-title.headline.font-weight-bold You need "THE" + SUPERLATIVE!
                      v-card-text
                          p.large Based on your answers to the flowchart, <b>{{word || 'the word'}}</b> DOES need an article, <mark>THE</mark>. This is because it has a superlative (e.g. most, best, biggest, smartest, etc) in front of it.
                          p.large There can only be ONE best or ONE smartest or ONE most beautiful, and for that reason we say THE + (best | smartest | most beautiful).
                          v-btn.mb-3(small, outlined @click="doReset" block) Try another one
                          v-btn(small, outlined @click="$emit('goBack')", block, color="warning") Back to Cheat Sheet

</template>

<script>
import { waitForElemToExist, scrollToTarget } from "@/helpers";

const Articles = require("articles");

export default {
  name: "Flowchart",
  props: { sentenceToAnalyze: { type: String, default: null } },
  data() {
    return {
      word: "",
      words: [],
      result: null,
      sentence: "",
      attributes: [],
      definiteArticleGeography: [
        "the Western Hemisphere",
        "the Eastern Hemisphere",
        "the Northern Hemisphere",
        "the Southern Hemisphere",
        "the Arctic",
        "the Antarctic",
        "the Far East",
        "the Middle East",
        "the North Pole",
        "the South Pole",
        "the United States (the U.S.)",
        "the United Kingdom (the UK)",
        "the Russian Federation",
        "the Netherlands",
        "the Philippines",
        "the Federal Republic of Germany",
        "the Republic of Cuba",
        "the Kingdom of Denmark",
        "the Kingdom of Spain",
        "the Commonwealth of Australia",
        "the state of California",
        "the state of New York",
        "the state of Texas",
        "the state of Washington",
        "the province of Ontario",
        "the province of Quebec",
        "the city of Athens",
        "the city of Moscow",
        "the city of New York",
        "the city of Oslo",
        "the city of Rome",
        "the city of Tokyo",
        "the city of Washington",
        "the town of Bedford",
        "the Freedom Trail",
        "the Blue Ridge Parkway",
        "the Trans-Canada Highway",
        "the Amazon / the Amazon River",
        "the Nile / the Nile River",
        "the Mississippi / the Mississippi River",
        "the Missouri / the Missouri River",
        "the Rio Grande",
        "the Yangtze / the Yangtze River",
        "the Thames / the Thames River / the River Thames",
        "the Seine / the Seine River",
        "the Danube / the Danube River",
        "the Amur / the Amur River",
        "the Dnepr / the Dnepr River / the Dnieper",
        "the Lena / the Lena River",
        "the Ob / the Ob River",
        "the Volga / the Volga River",
        "the Yenisei / the Yenisei River",
        "the Amu Darya",
        "the Red Sea",
        "the Black Sea",
        "the Caspian Sea",
        "the North Sea",
        "the Baltic Sea",
        "the Barents Sea",
        "the Bering Sea",
        "the Yellow Sea",
        "the Sea of Okhotsk",
        "the Sea of Japan",
        "the East Sea",
        "the Himalayas / the Himalaya / the Himalaya Mountains",
        "the Rocky Mountains / the Rockies",
        "the Balkan Mountains",
        "the Ural Mountains / the Urals",
        "the Caucasus Mountains / the Caucasus",
        "the Sahara / the Sahara Desert",
        "the Kalahari / the Kalahari Desert",
        "the Arabian Desert",
        "the Gobi / the Gobi Desert",
        "the Kara Kum",
      ],
      noArticleGeography: [
        "America",
        "North America",
        "South America",
        "Latin America",
        "Europe",
        "Asia",
        "Africa",
        "Australia",
        "Antarctica",
        "North Africa",
        "Western Europe",
        "Eastern Europe",
        "Central America",
        "South Asia",
        "Southeast Asia",
        "Central Asia",
        "Siberia",
        "Greenland",
        "Java",
        "Cyprus",
        "Madagascar",
        "Hokkaido",
        "Sakhalin",
        "Jamaica",
        "Ellis Island",
        "Indochina",
        "Kamchatka",
        "Korea",
        "Everest / Mount Everest",
        "Fuji / Mount Fuji / Fujiyama",
        "Kilimanjaro / Mount Kilimanjaro",
        "Elbrus / Mount Elbrus",
        "Etna / Mount Etna",
        "Vesuvius / Mount Vesuvius",
        "Mount Whitney",
        "Mount McKinley",
        "Mont Blanc",
        "Lassen Peak",
        "Capitol Hill",
        "Telegraph Hill",
        "America",
        "Australia",
        "Great Britain",
        "Britain",
        "England",
        "Canada",
        "Ireland",
        "New Zealand",
        "Argentina",
        "Belgium",
        "Brazil",
        "Chile",
        "China",
        "Denmark",
        "Egypt",
        "France",
        "Germany",
        "Greece",
        "Holland",
        "Hungary",
        "India",
        "Iran",
        "Israel",
        "Italy",
        "Japan",
        "Lebanon",
        "Luxembourg",
        "Mexico",
        "Mongolia",
        "Morocco",
        "Mozambique",
        "Norway",
        "Poland",
        "Russia",
        "Spain",
        "Saudi Arabia",
        "Switzerland",
        "Turkey",
        "Arkansas",
        "California",
        "Delaware",
        "Georgia",
        "Illinois",
        "Kentucky",
        "Louisiana",
        "Massachusetts",
        "New York / New York State",
        "Oklahoma",
        "Pennsylvania",
        "Rhode Island",
        "Texas",
        "Utah",
        "Washington / Washington State",
        "Alberta",
        "British Columbia",
        "Manitoba",
        "Newfoundland",
        "Ontario",
        "Quebec",
        "Saskatchewan",
        "Main Street",
        "Wall Street",
        "42nd Street",
        "Oak Alley",
        "Maple Boulevard",
        "Fifth Avenue",
        "Sixth Avenue",
        "Pennsylvania Avenue",
        "Broadway",
        "Lexington Road",
        "Route 66",
        "Highway 64",
      ],
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  computed: {},
  methods: {
    async init() {
      if (this.sentenceToAnalyze && this.sentenceToAnalyze.length > 0) {
        this.doReset();

        this.sentence = this.sentenceToAnalyze;
        await waitForElemToExist("#textField");
        scrollToTarget(document.getElementById("textField"));
      }
    },
    async doAnalysis(sentence) {
      this.doReset();
      this.getWordsFromSentence(sentence);
      await waitForElemToExist(".word");
      scrollToTarget(document.querySelector(".word"));
    },
    getWordsFromSentence(sentence) {
      if (!sentence || sentence.length < 5 || sentence.split(" ").length < 3) {
        return [];
      }

      this.word = null;
      const removePrepositions = (str) => {
        let res = str.toLowerCase();
        const prepositions = ["within", "in", "on", "under", "above", "of"];

        prepositions.forEach((preposition) => {
          res = res.split(" " + preposition + " ").join(" ");
        });
        return res;
      };
      const removeArticles = (str) => {
        let res = str.toLowerCase();
        const articles = ["the", "a", "an"];

        articles.forEach((article) => {
          res = res.split(" " + article + " ").join(" ");
        });
        return res;
      };
      const noPrepositions = removePrepositions(sentence);
      const noPunctuation = noPrepositions
        .toLowerCase()
        .split(".")
        .join("")
        .split("!")
        .join("")
        .split("?")
        .join("");

      const noArticles = removeArticles(noPunctuation);
      this.words = [...new Set(noArticles.trim().split(" "))];
    },
    doClickWord(word) {
      this.doReset();
      this.word = word.toUpperCase();
      this.doNextStep(1);
    },
    checkAttributes(...args) {
      let res = false;

      args.forEach((arg) => {
        if (this.attributes.includes(arg)) res = true;
      });

      return res;
    },
    doNextStep(attribute) {
      this.result = null;
      this.attributes.push(attribute);

      this.$nextTick(() => {
        const lastStep = document.querySelectorAll(".v-timeline-item")[
          document.querySelectorAll(".v-timeline-item").length - 1
        ];
        scrollToTarget(lastStep, 200);
      });
    },
    doShowResult(result) {
      this.result = result;
      this.$nextTick(() => {
        scrollToTarget(document.getElementById("result"));
      });
    },
    getIndefiniteArticle(str) {
      if (str.toLowerCase().startsWith("the ")) return str;

      return Articles.articlize(str);
    },
    doExample() {
      this.sentence = "Mary walks next to Thames river.";
    },
    doReset() {
      this.word = null;
      this.attributes = [];
      this.words = [];
      // this.sentence = null
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/colors.scss";
.headline {
  word-break: break-word;
}
.back-button {
  cursor: pointer;
  color: $primary;
}

.back-button:hover {
  background: lightyellow;
}
</style>
